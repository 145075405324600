import React, { Component } from "react";
import autoBind from "react-autobind";

import jonas from "./images/jonasGone.jpg";
import jonasprofil from "./images/jonasprofil.jpg";
import flyingMan from "./images/flyingMan.png";

import sexySound from "./sounds/sexySound.wav";

import "./App.css";
class App extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      clicks: 0,
      sexy: false,
      clickedCounter: false,
      clickedFlyingDude: false,
      animationDuration: 5000,
      isGone: false
    };
  }

  test() {
    this.setState({
      clickedFlyingDude: true
    });

    //document.getElementById("Test").className = "flyingMan2";
  }
  handlefaster() {
    const { animationDuration } = this.state;
    if (animationDuration > 300) {
      this.setState({ animationDuration: animationDuration - 200 });
    }
    if (animationDuration < 300) {
      this.setState({ isGone: true });
    }
  }

  render() {
    const {
      clicks,
      clickedCounter,
      clickedFlyingDude,
      animationDuration,
      isGone
    } = this.state;

    const sexyImage = jonasprofil;
    const flyImage = jonas;

    const flyingStyleTest = {
      width: "86px",
      height: "112px",
      position: "absolute",
      top: "56px"
    };
    const styleTestSpin = {
      width: "86px",
      height: "120px",
      position: "absolute",
      top: "56px",
      animationName: "spin",
      animationDuration: `${animationDuration}ms`,
      animationIterationCount: "infinite",
      animationTimingFunction: "linear"
    };
    const plopp = {
      padding: "100px"
    };

    let image = clicks > 68 ? sexyImage : flyImage;
    console.log({ State: this.state });
    return (
      <div className="App">
        {/* <header className="App-header">
          <img
            onClick={() =>
              clickedCounter && this.setState({ clicks: clicks + 1 })
            }
            src={image}
            className="App-logo"
            alt="logo"
          />
          {clicks < 69 && !isGone && (
            <img
              id="Test"
              src={flyingMan}
              alt="flyingMan"
              className="dude"
              style={!clickedFlyingDude ? flyingStyleTest : styleTestSpin}
              onClick={!clickedFlyingDude ? this.test : this.handlefaster}
            />
          )}
          {clicks > 68 && (
            <div>
              <audio ref="audiotag" src={sexySound} controls autoPlay />
            </div>
          )}
          <div
            onClick={() => this.setState({ clickedCounter: true })}
            className="counter"
          >
            {clicks < 69 ? clicks.toString() : "69"}
          </div>
        </header> */}

        {clicks < 5 && (
          <div onClick={() => this.setState({ clicks: clicks + 1 })}>
            Nemmen.. hei
          </div>
        )}

        {clicks >= 5 && (
          <div>
            <img
              onClick={() => this.setState({ clicks: clicks + 1 })}
              src={flyingMan}
              alt="plopp"
              style={plopp}
            />
            <p>Jasså.. finner du resten da?</p>
          </div>
        )}
      </div>
    );
  }
}

export default App;
